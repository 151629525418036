// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiCatalogoLanding: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS">
            <div className="_compact">
                <ApiLogo format={'all'} />
                <h2>Catálogo del SAT por API</h2>
                <p className="intro">
                    Para poder interactuar con el Catálogo del SAT requieres un usuario activado, y se realiza utilizando tu masterToken o el authToken de un cliente tuyo.
                </p>
                <p  className="intro">
                    Todas las rutas regresan los resultados completos con excepción de <b>Clave productos o servicios</b> y <b>Clave unidades</b>, donde podrás hacer filtrados básicos por texto o clave.
                </p>
                <p  className="intro">
                    Consulta el menú izquierdo para consultar el catálogo que deseas.
                </p>
                
            </div>
        </div>
    </Layout>
)

export default ApiCatalogoLanding
